import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { axiosService } from "../utils/axiosService";

/**
 *
 * @param {{url:string, cacheKey?:string, headers?:string, swrConfig?:Parameters<typeof useSWR>["2"],isExternalFetch:boolean}} param0
 * @returns
 */
export function useSWRFetcher({ url, cacheKey, headers, swrConfig, isExternalFetch }) {
  return useSWR(
    url,
    (...args) =>
      axiosService
        .request({
          method: "get",
          url: url,
          headers: headers,
        })
        .then((res) => (isExternalFetch ? res.data?.data?.data : res.data?.data)),
    swrConfig
  );
}

/**
 * @param {string[]} cacheKeys
 * @return {(cacheKeys2: string[])=> void | false}
 */
export const useInvalidateSWRKeys = (cacheKeys) => {
  // const { mutate } = useSWRConfig();
  return (cacheKeys2) => {
    let FinalCacheKeys = [];

    if (Array.isArray(cacheKeys)) {
      FinalCacheKeys = [...FinalCacheKeys, ...cacheKeys];
    }

    if (Array.isArray(cacheKeys2)) {
      FinalCacheKeys = [...FinalCacheKeys, ...cacheKeys2];
    }

    Array.isArray(FinalCacheKeys) &&
      FinalCacheKeys.forEach((cacheKey) =>
        mutate(
          (key) => {
            // console.log({ key, cacheKey, matched: key.indexOf(cacheKey) > -1 });
            return typeof key === "string" && key.indexOf(cacheKey) > -1;
          },
          undefined,
          {
            revalidate: true,
          }
        )
      );
  };
};

/**
 *
 * @param {{
 * url:string,
 * method: "POST" | "PATCH" | "PUT"
 * cacheKey?:string[],
 * headers?:string,
 * swrConfig?:Parameters<typeof useSWRMutation>["2"],
 * isExternalFetch:boolean
 * }} param0
 * @returns
 */
export function useSWRMutate({ url, revalidateCacheKeys, method, headers, swrConfig }) {
  const invalidateKeys = useInvalidateSWRKeys();
  return useSWRMutation(
    url,
    (url, { arg }) => {
      return axiosService.request({ method, url, headers, data: arg });
    },
    {
      ...swrConfig,
      onSuccess: (...onSuccessArgs) => {
        typeof swrConfig?.onSuccess === "function" &&
          swrConfig?.onSuccess(...onSuccessArgs);
        invalidateKeys(revalidateCacheKeys);
      },
    }
  );
}
