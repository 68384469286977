export const spribeData = {
  spribe1: {
    redirectUrl: "/casino/spribe/mines",
    imgUrl: "./images/spribe/Mines.jpg",
    name: "Mines",
  },
  spribe2: {
    redirectUrl: "/casino/spribe/goal",
    imgUrl: "./images/spribe/Goal.jpg",
    name: "Goal",
  },
  spribe3: {
    redirectUrl: "/casino/spribe/dice",
    imgUrl: "./images/spribe/Dice.jpg",
    name: "Dice",
  },
  spribe4: {
    redirectUrl: "/casino/spribe/aviator",
    imgUrl: "./images/spribe/Aviator.jpg",
    name: "Aviator",
  },
  spribe5: {
    redirectUrl: "/casino/spribe/plinko",
    imgUrl: "./images/spribe/Plinko.jpg",
    name: "Plinko",
  },
  spribe6: {
    redirectUrl: "/casino/spribe/miniroulette",
    imgUrl: "./images/spribe/MiniROulette.jpg",
    name: "Mini Roulette",
  },
  spribe7: {
    redirectUrl: "/casino/spribe/hilo",
    imgUrl: "./images/spribe/Hilonew.jpg",
    name: " Hilo",
  },
};
