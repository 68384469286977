import React from "react";
import { useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import LeftBarSports from "../../../components/LeftBarSports";
import MainSlider from "../../../../containers/MainSlider";
import CasinoSpribeGames from "../../../components/CasinoSpribeGames";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

const Crash = ({ isAuth }) => {
  const { banners } = useSelector((state) => state.app);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>

        <div className="mainBanner">
          <MainSlider banners={banners} />
        </div>

        <div className="SpribeSec">
          <CasinoSpribeGames />
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default Crash;
