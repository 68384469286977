import {
  APP_DETAILS,
  BANK_DETAILS,
  BANNERS,
  SHOW_RIGHT_SIDEBAR,
} from "./types";
import { axiosService } from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";

export const getAppDetails = () => (dispatch) => {
  const result = axiosService.get(API_URLS.APP.APP_DETAILS);
  result.then((res) => {
    if (res?.data?.error === false) {
      dispatch({
        type: APP_DETAILS,
        payload: res?.data?.data,
      });
    }
  });
};
export const getActiveBankDetails = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.BANK_DETAILS);
    result
      .then((res) => {
        dispatch({
          type: BANK_DETAILS,
          payload: res.data.data.bankDetails,
        });
      })
      .catch((err) => {
        console.debug(err);
      });
  } catch (err) {
    console.debug(err);
  }
};

export const getBanners = () => (dispatch) => {
  try {
    const result = axiosService.get(API_URLS.APP.GET_BANNERS);
    result.then((res) => {
      dispatch({
        type: BANNERS,
        payload: res.data?.data?.banners,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

export const handleRightSideBar = (booleanValue) => (dispatch) => {
  dispatch({
    type: SHOW_RIGHT_SIDEBAR,
    payload: booleanValue,
  });
};
