export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  popularGames: {
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/ultimateAB.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/andarbahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/autoroulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Speedroulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningroulette-.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Evolution",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lucky.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    Onedayteenpatti1: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CricketWar.jpg",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
  },

  roulettetab: {
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg1.png",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg2.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg3.png",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg4.png",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg5.png",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    PrestigeAutoRoulette: {
      redirectUrl: "/casino/ezg-prestige-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg6.png",
      alt: "",
      name: "Prestige Auto Roulette",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg7.jpg",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    ItalianRoulette: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg8.jpg",
      alt: "",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    RuletkaRussiaRoulette: {
      redirectUrl: "/casino/ez-ruletka-russia",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg9.png",
      alt: "",
      name: "Ruletka Russia Roulette",
      providerName: "Ezugi",
    },
    TurkishRoulette: {
      redirectUrl: "/casino/ezg-turkish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-ezg10.jpg",
      alt: "",
      name: "Turkish Roulette",
      providerName: "Ezugi",
    },

    AmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo1.png",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    RouletteEvo: {
      redirectUrl: "/casino/ezgevo-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo2.png",
      alt: "",
      name: "Roulette",
      providerName: "Evolution",
    },
    AutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-auto-roulette",
      code: "1000148",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo3.png",
      alt: "",
      name: "Auto Roulette",
      providerName: "Evolution",
    },
    FirstPersonAmericanRouletteEvo: {
      redirectUrl: "/casino/ezgevo-first-person-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo4.png",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Evolution",
    },
    SalonPriveRouletteEvo: {
      redirectUrl: "/casino/ezgevo-salon-prive-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo5.jpg",
      alt: "",
      name: "Salon Prive Roulette",
      providerName: "Evolution",
    },
    LightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo6.png",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000103",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo7.png",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolution",
    },
    FootballStudioRouletteEvo: {
      redirectUrl: "/casino/ezgevo-football-studio-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo8.png",
      alt: "",
      name: "Football Studio Roulette",
      providerName: "Evolution",
    },
    SpeedRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo7.png",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    DoubleBallRouletteEvo: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo9.png",
      alt: "",
      name: "Double Ball Roulette",
      providerName: "Evolution",
    },
    TurkishLightningRouletteEvo: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/roulette-evo2.png",
      alt: "",
      name: "Turkish Lightning Roulette",
      providerName: "Evolution",
    },
  },

  pokertab: {
    vivoTeenpatti: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/vivoTeenPatti.jpg",
      alt: "",
      name: "Teen Patti Vivo",
      providerName: "ezugi",
    },
    BetOnTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg1.jpg",
      alt: "",
      name: "Bet On Teen Patti",
      providerName: "Ezugi",
    },
    OneDayTeenPatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg2.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    TeenPatti3Card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg3.png",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    CasinoHoldem: {
      redirectUrl: "/casino/ezg-casino-holdem",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg4.png",
      alt: "",
      name: "Casino Hold'em",
      providerName: "Ezugi",
    },
    RoyalPoker: {
      redirectUrl: "/casino/ezg-royal-poker",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg5.jpg",
      alt: "",
      name: "Russian Poker",
      providerName: "Ezugi",
    },
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg7.jpg",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Ezugi",
    },

    CasinoHoldemEvo: {
      redirectUrl: "/casino/ezgevo-casino-holdem-evo",
      code: "1000111",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg4.png",
      alt: "",
      name: "Casino Hold'em",
      providerName: "Evolution",
    },
    TexasHoldemBonusPokerEvo: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000111",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg9.jpg",
      alt: "",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },
    UltimatetexasholdemEvo: {
      redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
      code: "1000151",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg8.png",
      alt: "",
      name: "Ultimate Texas Hold'em",
      providerName: "Evolution",
    },
    ThreeCardPokerEvo: {
      redirectUrl: "/casino/ezgevo-three-card-poker",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/poker-ezg6.jpg",
      alt: "",
      name: "Three Card Poker",
      providerName: "Evolution",
    },
  },

  baccarattab: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg1.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg2.png",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg3.png",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg4.png",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg5.png",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg6.jpg",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg7.jpg",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    SpeedFortuneBaccarat: {
      redirectUrl: "/casino/ezg-speed-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg8.png",
      alt: "",
      name: "Speed Fortune Baccarat",
      providerName: "Ezugi",
    },
    KnockoutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg9.jpg",
      alt: "",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    FiestaBaccarat: {
      redirectUrl: "/casino/ezg-fiesta-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg10.jpg",
      alt: "",
      name: "Fiesta Baccarat",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg11.jpg",
      alt: "",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat2: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg12.jpg",
      alt: "",
      name: "Casino Marina Baccarat 2",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat3: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg13.jpg",
      alt: "",
      name: "Casino Marina Baccarat 3",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat4: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg14.jpg",
      alt: "",
      name: "Casino Marina Baccarat 4",
      providerName: "Ezugi",
    },
    VIPNoCommissionSpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-vip-no-commission-speed-cricket-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-ezg15.png",
      alt: "",
      name: "VIP No Commission Speed Cricket Baccarat",
      providerName: "Ezugi",
    },

    BaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo1.png",
      alt: "",
      name: "Baccarat A",
      providerName: "Evolution",
    },
    BaccaratBEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo2.png",
      alt: "",
      name: "Baccarat B",
      providerName: "Evolution",
    },
    BaccaratCEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Baccarat C",
      providerName: "Evolution",
    },
    BaccaratControlSqueezeEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-control-squeeze",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo1.png",
      alt: "",
      name: "Baccarat Control Squeeze",
      providerName: "Evolution",
    },
    BaccaratSqueezeEvo: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo2.png",
      alt: "",
      name: "Baccarat Squeeze",
      providerName: "Evolution",
    },
    GoldenWealthBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-golden-wealth-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Golden Wealth Baccarat",
      providerName: "Evolution",
    },
    HindiSpeedBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-hindi-speed-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo4.png",
      alt: "",
      name: "Hindi Speed Baccarat",
      providerName: "Evolution",
    },
    LightningBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Lightning Baccarat",
      providerName: "Evolution",
    },
    NoCommissionBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo5.png",
      alt: "",
      name: "No Commission Baccarat-EVO",
      providerName: "Evolution",
    },
    NoCommissionSpeedBaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-speed-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "No Commission Speed Baccarat A",
      providerName: "Evolution",
    },
    NoCommissionSpeedBaccaratBEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-speed-baccarat-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo4.png",
      alt: "",
      name: "No Commission Speed Baccarat B",
      providerName: "Evolution",
    },
    NoCommissionSpeedBaccaratCEvo: {
      redirectUrl: "/casino/ezgevo-no-commission-speed-baccarat-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo1.png",
      alt: "",
      name: "No Commission Speed Baccarat C",
      providerName: "Evolution",
    },
    PeekBaccaratEvo: {
      redirectUrl: "/casino/ezgevo-peek-baccarat",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Peek Baccarat",
      providerName: "Evolution",
    },
    SpeedBaccaratAEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo4.png",
      alt: "",
      name: "Speed Baccarat A",
      providerName: "Evolution",
    },
    SpeedBaccaratBEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Speed Baccarat B",
      providerName: "Evolution",
    },
    SpeedBaccaratCEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo1.png",
      alt: "",
      name: "Speed Baccarat C",
      providerName: "Evolution",
    },
    SpeedBaccaratDEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo3.jpg",
      alt: "",
      name: "Speed Baccarat D",
      providerName: "Evolution",
    },
    SpeedBaccaratEEvo: {
      redirectUrl: "/casino/ezgevo-speed-baccarat-e",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat-evo6.jpg",
      alt: "",
      name: "Speed Baccarat e",
      providerName: "Evolution",
    },
  },

  andarBahar: {
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar-ezg1.png",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/vivoAndarBahar.jpg",
      alt: "",
      name: "Andar Bahar Vivo",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar-ezg2.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    CasinoMarinaAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar-ezg3.jpg",
      alt: "",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/andarbahar-ezg4.png",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
  },

  lucky7: {
    lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lucky7/lucky-ezg1.png",
      alt: "",
      name: "lucky7",
      providerName: "Ezugi",
    },
  },

  dicegames: {
    SicBo: {
      redirectUrl: "/casino/ezg-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg2.jpg",
      alt: "",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
    UltimateSicBo: {
      redirectUrl: "/casino/ezg-ultimate-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg4.jpg",
      alt: "",
      name: "Ultimate Sic Bo",
      providerName: "Ezugi",
    },

    LightningDiceEvo: {
      redirectUrl: "/casino/ezg-lightning-dice",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg1.jpeg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    SuperSicBoEvo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg3.png",
      alt: "",
      name: "Super Sic Bo",
      providerName: "Evolution",
    },
    CrapsEvo: {
      redirectUrl: "/casino/ezgevo-craps",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg5.png",
      alt: "",
      name: "Craps",
      providerName: "Evolution",
    },
    FirstPersonCrapsEvo: {
      redirectUrl: "/casino/ezgevo-first-person-craps",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg6.png",
      alt: "",
      name: "First Person Craps",
      providerName: "Evolution",
    },
    BacBoEvo: {
      redirectUrl: "/casino/ezgevo-bac-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/dicegames-ezg7.jpg",
      alt: "",
      name: "Bac Bo",
      providerName: "Evolution",
    },
  },

  cards32: {
    Cards32: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/card/cards32-ezg1.jpg",
      alt: "",
      name: "32 Cards",
      providerName: "Ezugi",
    },
  },

  blackjacktab: {
    GoldBlackjack4: {
      redirectUrl: "/casino/ezg-gold-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg1.png",
      alt: "",
      name: "Gold Blackjack 4",
      providerName: "Ezugi",
    },
    GoldBlackjack5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg2.png",
      alt: "",
      name: "Gold Blackjack 5",
      providerName: "Ezugi",
    },
    PlatinumBlackjack1: {
      redirectUrl: "/casino/ez-blackjack-platinum-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg3.jpg",
      alt: "",
      name: "Platinum Blackjack 1",
      providerName: "Ezugi",
    },
    Blackjack1: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg4.jpg",
      alt: "",
      name: "Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg5.png",
      alt: "",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    Blackjack7: {
      redirectUrl: "/casino/ezg-blackjack-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg6.jpg",
      alt: "",
      name: "Blackjack 7",
      providerName: "Ezugi",
    },
    UnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg7.jpg",
      alt: "",
      name: "Unlimited Blackjack",
      providerName: "Ezugi",
    },
    RussianBlackjack1: {
      redirectUrl: "/casino/ezg-Russian-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg8.jpg",
      alt: "",
      name: "Russian Blackjack 1",
      providerName: "Ezugi",
    },
    RumbaBlackjack1: {
      redirectUrl: "/casino/ezg-rumba-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg9.jpg",
      alt: "",
      name: "Rumba Blackjack 1",
      providerName: "Ezugi",
    },
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg10.jpg",
      alt: "",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack4: {
      redirectUrl: "/casino/ezg-rumba-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg11.jpg",
      alt: "",
      name: "Rumba Blackjack 4",
      providerName: "Ezugi",
    },
    ItalianBlackjack: {
      redirectUrl: "/casino/ezg-Italian-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg12.jpg",
      alt: "",
      name: "Italian Blackjack",
      providerName: "Ezugi",
    },
    TurkishBlackjack1: {
      redirectUrl: "/casino/ezg-turkish-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg13.png",
      alt: "",
      name: "Turkish Blackjack 1",
      providerName: "Ezugi",
    },
    TurkishUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-turkish-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg14.jpg",
      alt: "",
      name: "Turkish Unlimited Blackjack",
      providerName: "Ezugi",
    },
    FiestaUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-fiesta-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg15.png",
      alt: "",
      name: "Fiesta Unlimited Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack2: {
      redirectUrl: "/casino/ez-rumba-blackjack-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg16.jpg",
      alt: "",
      name: "Rumba Blackjack 2",
      providerName: "Ezugi",
    },
    RussianBlackjack2: {
      redirectUrl: "/casino/ezg-Russian-blackjack-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg17.jpg",
      alt: "",
      name: "Russian Blackjack 2",
      providerName: "Ezugi",
    },
    SalonPriveBlackjack: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg18.png",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Ezugi",
    },
    VIPSurrenderBlackjack: {
      redirectUrl: "/casino/ezg-vip-surrender-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg19.jpg",
      alt: "",
      name: "VIP Surrender Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack3: {
      redirectUrl: "/casino/ez-rumba-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg20.jpg",
      alt: "",
      name: "Rumba Blackjack 3",
      providerName: "Ezugi",
    },
    RomanianBlackjack: {
      redirectUrl: "/casino/ezg-romanian-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-ezg21.png",
      alt: "",
      name: "Romanian Blackjack 3",
      providerName: "Ezugi",
    },

    BlackjackC: {
      redirectUrl: "/casino/ezgevo-blackjack-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo1.png",
      alt: "",
      name: "Blackjack C",
      providerName: "Evolution",
    },
    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo2.jpg",
      alt: "",
      name: "Blackjack Party",
      providerName: "Evolution",
    },
    BlackjackSilverA: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo3.png",
      alt: "",
      name: "Blackjack Silver A",
      providerName: "Evolution",
    },
    BlackjackSilverB: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo4.png",
      alt: "",
      name: "Blackjack Silver B",
      providerName: "Evolution",
    },
    BlackjackSilverC: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo5.jpg",
      alt: "",
      name: "Blackjack Silver C",
      providerName: "Evolution",
    },
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo1.png",
      alt: "",
      name: "Blackjack Silver D",
      providerName: "Evolution",
    },
    BlackjackSilverE: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-e",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo6.jpg",
      alt: "",
      name: "Blackjack Silver E",
      providerName: "Evolution",
    },
    BlackjackSilverF: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-f",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo7.jpg",
      alt: "",
      name: "Blackjack Silver F",
      providerName: "Evolution",
    },
    BlackjackSilverG: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-g",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo8.jpg",
      alt: "",
      name: "Blackjack Silver G",
      providerName: "Evolution",
    },
    BlackjackVIP1: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-1",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo9.png",
      alt: "",
      name: "Blackjack VIP 1",
      providerName: "Evolution",
    },
    BlackjackVIP2: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-2",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo10.png",
      alt: "",
      name: "Blackjack VIP 2",
      providerName: "Evolution",
    },
    BlackjackVIP11: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-11",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjack-evo6.jpg",
      alt: "",
      name: "Blackjack VIP 11",
      providerName: "Evolution",
    },
  },

  gameShows: {
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/roulette-ezg3.png",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-ezg2.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },

    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo1.png",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    FirstPersonDreamCatcher: {
      redirectUrl: "/casino/ezgevo-first-person-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo2.png",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    MegaBall: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo3.png",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    FirstPersonMegaBall: {
      redirectUrl: "/casino/ezgevo-first-person-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo4.png",
      alt: "",
      name: "First Person Mega Ball",
      providerName: "Evolution",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezgevo-side-bet-city",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo5.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    FootballStudio: {
      redirectUrl: "/casino/ezgevo-football-studio",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo6.png",
      alt: "",
      name: "Football Studio",
      providerName: "Evolution",
    },
    FirstPersonTopCard: {
      redirectUrl: "/casino/ezgevo-first-person-top-card",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo7.jpg",
      alt: "",
      name: "First Person Top Card",
      providerName: "Evolution",
    },
    GonzosTreasureHunt: {
      redirectUrl: "/casino/ezgevo-gonzos-treasure-hunt",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo8.png",
      alt: "",
      name: "Gonzo's Treasure Hunt",
      providerName: "Evolution",
    },
    CashOrCrash: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo9.png",
      alt: "",
      name: "Cash or Crash",
      providerName: "Evolution",
    },
    BacBo: {
      redirectUrl: "/casino/ezgevo-bac-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo10.jpg",
      alt: "",
      name: "Bac Bo",
      providerName: "Evolution",
    },
    ExtraChilliEpicSpins: {
      redirectUrl: "/casino/ezgevo-extra-chilli-epic-spins",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo11.png",
      alt: "",
      name: "Extra Chilli Epic Spins",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo12.png",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    FirstPersonDragonTiger: {
      redirectUrl: "/casino/ezgevo-first-person-dragon-tiger",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo13.jpg",
      alt: "",
      name: "First Person Dragon Tiger",
      providerName: "Evolution",
    },
    MONOPOLYBigBaller: {
      redirectUrl: "/casino/evo-monopoly-big-baller",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo14.png",
      alt: "",
      name: "MONOPOLY Big Baller",
      providerName: "Evolution",
    },
    MONOPOLYLive: {
      redirectUrl: "/casino/evo-monopoly-live",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/gameshows-evo15.png",
      alt: "",
      name: "MONOPOLY Live",
      providerName: "Evolution",
    },
  },

  aviator: {
    Aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-aviator.png",
      alt: "aviator",
      name: "Aviator",
      providerName: "Spribe",
    },
  },

  turbo: {
    Dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-dice.png",
      alt: "dice",
      name: "Dice",
      providerName: "Spribe",
    },
    Plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-plinko.png",
      alt: "plinko",
      name: "Plinko",
      providerName: "Spribe",
    },
    Goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-goal.png",
      alt: "goal",
      name: "Goal",
      providerName: "Spribe",
    },
    Hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-hilo.png",
      alt: "hilo",
      name: "Hi-lo",
      providerName: "Spribe",
    },
    Mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-mines.png",
      alt: "mines",
      name: "Mines",
      providerName: "Spribe",
    },
    MiniRoulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-miniroulette.png",
      alt: "mini roulette",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
  },

  dragontiger: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerEvolution: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerrevo.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGCricket2020: {
      redirectUrl: "/casino/sn-rng-cricket-2020",
      code: "RCKT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCricket2020.jpg",
      alt: "",
      name: "RNG Cricket 2020",
      providerName: "Supernowa",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
  },

  netent: {
    BloodSuckers: {
      redirectUrl: "/casino/ezgne-blood-suckers",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img1.png",
      alt: "blood suckers",
      name: "Blood Suckers",
      providerName: "netent",
    },
    BloodSuckersII: {
      redirectUrl: "/casino/qtechnetent-blood-suckers-ii",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img2.png",
      alt: "blood suckers II",
      name: "Blood Suckers II",
      providerName: "netent",
    },
    DarkKingForbiddenRiches: {
      redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img3.png",
      alt: "dark king forbidden riches",
      name: "Dark King: Forbidden Riches",
      providerName: "netent",
    },
    DazzleMeMegaways: {
      redirectUrl: "/casino/ezgne-dazzle-me-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img4.png",
      alt: "dazzle me megaways",
      name: "Dazzle Me Megaways",
      providerName: "netent",
    },
    DeadOrAlive2FeatureBuyDazzleMeMegaways: {
      redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img5.png",
      alt: "dead or alive 2 feature buy",
      name: "Dead or Alive 2 Feature Buy",
      providerName: "netent",
    },
    DivineFortuneMegaways: {
      redirectUrl: "/casino/ezgne-divine-fortune-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img6.png",
      alt: "divine fortune megaways",
      name: "Divine Fortune Megaways",
      providerName: "netent",
    },
    FinnsGoldenTavern: {
      redirectUrl: "/casino/qtechnetent-finns-golden-tavern",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img7.png",
      alt: "finn's golden tavern",
      name: "Finn's Golden Tavern",
      providerName: "netent",
    },
    FruitShop: {
      redirectUrl: "/casino/ezgne-fruit-shop",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img8.png",
      alt: "fruit shop",
      name: "Fruit Shop",
      providerName: "netent",
    },
    FruitShopChristmasEdition: {
      redirectUrl: "/casino/ezgne-fruit-shop-christmas-edition",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img9.png",
      alt: "fruit shop christmas edition",
      name: "Fruit Shop Christmas Edition",
      providerName: "netent",
    },
    FruitShopMegaways: {
      redirectUrl: "/casino/ezgne-fruit-shop-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img10.png",
      alt: "fruit shop megaways",
      name: "Fruit Shop Megaways",
      providerName: "netent",
    },
    GonzosQuest: {
      redirectUrl: "/casino/qtechnetent-gonzos-quest",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img11.png",
      alt: "gonzo's quest",
      name: "Gonzo's Quest",
      providerName: "netent",
    },
    JackHammer2FishyBusiness: {
      redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img12.png",
      alt: "jack hammer 2: fishy business",
      name: "Jack Hammer 2: Fishy Business",
      providerName: "netent",
    },
    ParthenonQuestForImmortality: {
      redirectUrl: "/casino/qtechnetent-parthenon-quest-for-immortality",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img13.png",
      alt: "parthenon: quest for immortality",
      name: "Parthenon: Quest for Immortality",
      providerName: "netent",
    },
    PyramidQuestForImmortality: {
      redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img14.png",
      alt: "Pyramid: quest for immortality",
      name: "Pyramid: Quest for Immortality",
      providerName: "netent",
    },
    ReelRush: {
      redirectUrl: "/casino/ezgne-reel-rush",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img15.png",
      alt: "reel rush",
      name: "Reel Rush",
      providerName: "netent",
    },
    RichesOfMidgardLandAndExpand: {
      redirectUrl: "/casino/ezgne-riches-of-midgard-land-and-expand",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img16.png",
      alt: "riches of midgard: land and expand",
      name: "Riches of Midgard: Land and Expand",
      providerName: "netent",
    },
    RomeTheGoldenAge: {
      redirectUrl: "/casino/ezgne-rome-the-golden-age",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img17.png",
      alt: "rome: the golden age",
      name: "Rome: The Golden Age",
      providerName: "netent",
    },
    SecretsOfAtlantis: {
      redirectUrl: "/casino/ezgne-secrets-of-atlantis",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img18.png",
      alt: "secrets of atlantis",
      name: "Secrets of Atlantis",
      providerName: "netent",
    },
  },

  redtiger: {
    Strike777: {
      redirectUrl: "/casino/ezgrt-777-strike",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img1.png",
      alt: "777 strike",
      name: "777 Strike",
      providerName: "redtiger",
    },
    ZeusLightningPowerReels: {
      redirectUrl: "/casino/ezgrt-zeus-lightning-power-reels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img2.png",
      alt: "zeus lightning power reels",
      name: "Zeus Lightning Power Reels",
      providerName: "redtiger",
    },
    WingsOfRa: {
      redirectUrl: "/casino/ezgrt-wings-of-ra",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img3.png",
      alt: "wings of ra",
      name: "Wings of Ra",
      providerName: "redtiger",
    },
    AztecSpins: {
      redirectUrl: "/casino/ezgrt-aztec-spins",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img4.png",
      alt: "aztec spins",
      name: "Aztec Spins",
      providerName: "redtiger",
    },
    BountyRaid: {
      redirectUrl: "/casino/ezgrt-bounty-raid",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img5.png",
      alt: "bounty raid",
      name: "Bounty Raid",
      providerName: "redtiger",
    },
    DragonKingLegendOfTheSeas: {
      redirectUrl: "/casino/qtechredtiger-dragon-king-legend-of-the-seas",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img6.png",
      alt: "dragon king: legend of the seas",
      name: "Dragon King: Legend of the Seas",
      providerName: "redtiger",
    },
    DragonsFire: {
      redirectUrl: "/casino/ezgrt-dragons-fire",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img7.png",
      alt: "dragon's fire",
      name: "Dragon's Fire",
      providerName: "redtiger",
    },
    DragonsFireInfinireels: {
      redirectUrl: "/casino/qtechredtiger-dragons-fire-infinireels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img8.png",
      alt: "dragon's fire infinireels",
      name: "Dragon's Fire INFINIREELS",
      providerName: "redtiger",
    },
    DragonsFireMegaways: {
      redirectUrl: "/casino/ezgrt-dragons-fire-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img9.png",
      alt: "dragon's fire megaways",
      name: "Dragon's Fire Megaways",
      providerName: "redtiger",
    },
    DragonsLuck: {
      redirectUrl: "/casino/ezgrt-dragons-luck",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img10.png",
      alt: "dragon's luck",
      name: "Dragon's Luck",
      providerName: "redtiger",
    },
    DragonsLuckDeluxe: {
      redirectUrl: "/casino/ezgrt-dragons-luck-deluxe",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img11.png",
      alt: "dragons luck deluxe",
      name: "Dragon's Luck Deluxe",
      providerName: "redtiger",
    },
    DragonsLuckMegaways: {
      redirectUrl: "/casino/qtechredtiger-dragons-luck-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img12.png",
      alt: "dragon's luck megaways",
      name: "Dragon's Luck Megaways",
      providerName: "redtiger",
    },
    DragonsLuckPowerReels: {
      redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img13.png",
      alt: "dragon's luck power reels",
      name: "Dragon's Luck Power Reels",
      providerName: "redtiger",
    },
    DynamiteRichesMegaways: {
      redirectUrl: "/casino/qtechredtiger-dynamite-riches-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img14.png",
      alt: "dynamite riches megaways",
      name: "Dynamite Riches Megaways",
      providerName: "redtiger",
    },
    FortuneHouse: {
      redirectUrl: "/casino/ezgrt-fortune-house",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img15.png",
      alt: "fortune house",
      name: "Fortune House",
      providerName: "redtiger",
    },
    GoldenCryptex: {
      redirectUrl: "/casino/ezgrt-golden-cryptex",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img16.png",
      alt: "golden cryptex",
      name: "Golden Cryptex",
      providerName: "redtiger",
    },
    GoldenOffer: {
      redirectUrl: "/casino/ezgrt-golden-offer",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img17.png",
      alt: "golden offer",
      name: "Golden Offer",
      providerName: "redtiger",
    },
    GonzosQuestMegaways: {
      redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img18.png",
      alt: "gonzo's quest megaways",
      name: "Gonzo's Quest Megaways",
      providerName: "redtiger",
    },
  },
};
