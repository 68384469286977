import React, { useEffect, useState } from "react";
import { casinoSection } from "../../lib/data/casinoSection.data";
import AllgamesCat from "../../assets/images/categories/all-icon.png";
import NetEntCat from "../../assets/images/categories/video-slots.png";
import RedTigerCat from "../../assets/images/categories/video-slots.png";
import Play from "../../assets/images/play-btn.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../containers/ModalWrapper";
import { APP_CONST } from "../../config/const";

const tabsArray = [
  {
    id: 0,
    name: "All",
    key: "all",
    imgSrc: AllgamesCat,
  },
  {
    id: 1,
    name: "Video Slots",
    key: "netent",
    imgSrc: NetEntCat,
  },
  {
    id: 2,
    name: "Video Slots",
    key: "redtiger",
    imgSrc: RedTigerCat,
  },
];
const providersArray = [
  { id: 1, name: "NetEnt", eventKey: "netent" },
  { id: 2, name: "Red Tiger", eventKey: "redtiger" },
];
const CasinoNetEntGames = () => {
  const [activeProvider, setActiveProvider] = useState(providersArray[0]);
  const [activeTab, setActiveTab] = useState(tabsArray[0]);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const manageProvider = (provider) => {
    manageTab(tabsArray[0]);
    setActiveProvider(provider);
  };

  const manageTab = (tab) => {
    setActiveTab(tab);
  };

  const handleAuthRedirection = (navigationUrl) => {
    if (isAuth) {
      navigate(navigationUrl);
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <div className="casino-games">
      <div className="game-filter">
        <div className="providers">
          <ul>
            {providersArray.map((p, index) => {
              return (
                <li key={index}>
                  <a
                    href="#"
                    className={
                      activeProvider.eventKey === p.eventKey ? "active" : ""
                    }
                    onClick={() => manageProvider(p)}
                  >
                    {p.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <ul className="categories spribe">
          {tabsArray?.map((tab, index) => {
            return (
              <>
                {/* {casinoSection} */}
                {((casinoSection?.[tab.key] &&
                  Object.values(casinoSection?.[tab.key]).some(
                    (s) =>
                      s?.providerName?.toLowerCase() ===
                      activeProvider?.eventKey
                  )) ||
                  tab.key === "all") && (
                  <li key={index} onClick={() => manageTab(tab)}>
                    <a
                      href="javascript:void(0)"
                      className={activeTab.key === tab.key ? "active" : ""}
                    >
                      <img src={tab?.imgSrc} alt="roulette" />
                      <span>{tab?.name}</span>
                    </a>
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </div>

      <div className="all-images SpribePage">
        <ul>
          {activeTab?.key !== "all"
            ? casinoSection?.[activeTab.key] &&
              Object.values(casinoSection?.[activeTab?.key])
                .filter(
                  (f) =>
                    f?.providerName?.toLowerCase() ===
                    activeProvider?.eventKey?.toLocaleLowerCase()
                )
                .map((casino, index) => {
                  return (
                    <li key={index}>
                      <a
                        onClick={() =>
                          handleAuthRedirection(casino?.redirectUrl)
                        }
                      >
                        <img src={casino?.imgUrl} />
                        <p>{casino?.name}</p>
                        <div className="overlay">
                          <img src={Play} className="play button" />
                          <span>PLAY NOW</span>
                        </div>
                      </a>
                    </li>
                  );
                })
            : casinoSection &&
              Object.keys(casinoSection).map((key) => {
                return (
                  <>
                    {tabsArray.some((s) => s.key === key) &&
                      Object.values(casinoSection?.[key]).find(
                        (f) =>
                          f?.providerName?.toLowerCase() ===
                          activeProvider?.eventKey?.toLocaleLowerCase()
                      ) && (
                        <>
                          <div className="category-heading">
                            <img
                              src={
                                tabsArray.find((f) => f.key === key)?.imgSrc ||
                                ""
                              }
                            />
                            <h5>
                              {tabsArray.find((f) => f.key === key)?.name}
                            </h5>
                          </div>
                          {Object.values(casinoSection?.[key])
                            .filter(
                              (f) =>
                                f?.providerName?.toLowerCase() ===
                                activeProvider?.eventKey?.toLocaleLowerCase()
                            )
                            .map((casino, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    onClick={() =>
                                      handleAuthRedirection(casino?.redirectUrl)
                                    }
                                  >
                                    <img src={casino?.imgUrl} />
                                    <p>{casino?.name}</p>
                                    <div className="overlay">
                                      <img src={Play} className="play button" />
                                      <span>PLAY NOW</span>
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                        </>
                      )}
                  </>
                );
              })}
        </ul>
        {showAuthModal && (
          <ModalWrapper
            defaultShow={APP_CONST.AUTH_MODAL.LOGIN}
            handleClose={setShowAuthModal}
          />
        )}
      </div>
    </div>
  );
};
export default CasinoNetEntGames;
