import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";

const MainSlider = ({ banners }) => {
  const settings = {
    dots: true,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings}>
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="img_box" key={index}>
                <img src={b.link} alt="First Slide" />
              </div>
            );
          })}
      </Slider>
    </>
  );
};

export default MainSlider;
