import React, { useEffect, useLayoutEffect, useState } from "react";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";

const ModalWrapper = ({ defaultShow, handleClose: handleComponentSate }) => {
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
    forgotpassword: false,
    resetpassword: false,
  });
  useEffect(() => {
    if (defaultShow && defaultShow in showAuthModals) {
      handleModalShow(defaultShow);
    }
  }, [defaultShow]);
  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (
    modalName,
    actionType = APP_CONST.AUTH_MODAL_ACTION_TYPE.AUTO
  ) => {
    if (actionType == APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL) {
      handleComponentSate(false);
    }
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  return (
    <>
      {showAuthModals.login && (
        <LoginModal
          show={showAuthModals.login}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.LOGIN, type)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.mobileNumber && (
        <MobileNumberModal
          show={showAuthModals.mobileNumber}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER, type)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.verifyNumber && (
        <MobileNumberVerifyModal
          show={showAuthModals.verifyNumber}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER, type)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.register && (
        <RegisterModal
          show={showAuthModals.register}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.REGISTER, type)
          }
          handleModalShow={handleModalShow}
        />
      )}

      {showAuthModals.forgotpassword && (
        <ForgotPasswords
          show={showAuthModals.forgotpassword}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, type)
          }
          handleModalShow={handleModalShow}
        />
      )}
      {showAuthModals.resetpassword && (
        <ResetPassword
          show={showAuthModals.resetpassword}
          handleClose={(type) =>
            handleModalClose(APP_CONST.AUTH_MODAL.RESET_PASSWORD, type)
          }
          handleModalShow={handleModalShow}
        />
      )}
    </>
  );
};
export default ModalWrapper;
