import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/netent.png";
import ProviderLogo3 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo4 from "../assets/images/providers/redtiger.png";
import ProviderLogo5 from "../assets/images/providers/spribe.png";
import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom/dist";

import HomeIcon from "../assets/images/icons/homeicon.png";
import MenuIcon from "../assets/images/icons/menuicon.png";
import LiveCasinoIcon from "../assets/images/icons/live-casino-icon.svg";
import CasinoIcon from "../assets/images/icons/casino-icon.svg";
import AviatorIcon from "../assets/images/icons/aviator-icon.svg";
import ExchangeIcon from "../assets/images/icons/exchange-icon.svg";
import DepositIcon from "../assets/images/icons/deposit.png";
import EzugiIcon1 from "../assets/images/icons/Ezugi.png";
import Age18 from "../assets/images/age18.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import LogInIcon from "../assets/images/icons/login-white.png";
import PaymentMethod from "../assets/images/payment-method.png";
import SupportIcon from "../assets/images/icons/Support-white.png";
import WalletIcon1 from "../assets/images/icons/wallet.png";
import { handleRightSideBar } from "../redux/app/actions";
import { useDispatch } from "react-redux";
import XPGIcon from "../assets/images/icons/poker-chip.png";

import EzugiImg from "../assets/images/providers/ezugi-img.png";
import EvolutionImg from "../assets/images/providers/evolution-img.png";
import SpribeImg from "../assets/images/providers/spribe-img.png";
import NetentImg from "../assets/images/providers/netent-img.png";
import PragmaticImg from "../assets/images/providers/pragmatic-img.png";
import RedTigerImg from "../assets/images/providers/red-tiger-img.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const { showRightSideBar } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");

  const [showToggle, setShowToggle] = useState(false);
  const htmlElement = document.documentElement;
  useEffect(() => {
    if (showToggle) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("header_open");
    } else {
      htmlElement.style.overflow = "";
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  // useEffect(() => {
  //   setShowToggle(window.screen.width < 992);
  // }, []); //condition will revert below this device width

  const footerPages = [
    "menu",
    "home",
    "crash",
    "sports",
    "casino",
    "evolution",
    "ezugi",
    "gatewaylist",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  // const htmlElement = document.documentElement;
  // useEffect(() => {
  //   if (showToggle) {
  //     htmlElement.style.overflow = "hidden";
  //   } else {
  //     htmlElement.style.overflow = "auto";
  //   }
  // }, [showToggle]);

  return (
    <footer className="footer">
      <Container>
        <div className="providers_sec d-none">
          <Row>
            <Col md={6} xs={12}>
              <div className="provider-mob-border">
                <div className="heading mb-4">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>
                      Play Your favorite Casino Games with{" "}
                      <span>Top Providers</span>
                    </p>
                  </div>
                </div>

                <div className="providers_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </a>
                  <a href="/casino/pragmatic" className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </a>
                  <a href="/casino/onetouch" className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="">
                <div className="heading payment-heading mb-4 ">
                  <div className="text">
                    <h5>Payment Gateway</h5>
                    <p>
                      Accept Process &amp; disburse{" "}
                      <span>Digital Payments </span>
                      for your bussiness
                    </p>
                  </div>
                </div>

                <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={PaymentLogo1} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/pragmatic"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={PaymentLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo4} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo5} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo6} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo7} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-none">
          <div className="providers_img">
            <a href="javascript:void(0)">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="javascript:void(0)">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="/contactuss">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplays">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrules">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us
            </a>
            {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
          </div>

          <div class="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
            <img
              src={Betfair}
              loading="lazy"
              class="mb-3  mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="javascript:void(0)">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                class="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.FACEBOOK != ""
                      ? appDetails?.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != ""
                      ? appDetails?.INSTATGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="our-provider">
          <h5>Our Game Provider</h5>

          <div className="providers_logo">
            <a href="javascript:void(0)" className="logo_box">
              <img src={EzugiImg} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={EvolutionImg} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={SpribeImg} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={NetentImg} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={PragmaticImg} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={RedTigerImg} alt="Provider Logo" />
            </a>
          </div>
        </div>

        <div className="payment-methods">
          <h5>Available Payment Methods</h5>

          <div className="img-box">
            <img src={PaymentMethod} alt="Provider Logo" />
          </div>
        </div>

        <div className="foot-text">
          <img src={appDetails?.LOGO_URL} alt="Logo" />
          <img src={Age18} alt="age 18+" />
          <p>
            In order to register for this website, the user is required to
            accept the <a href="/terms">General Terms and Conditions</a>. In the
            event the General Terms and Conditions are updated, existing users
            may choose to discontinue using the products and services before the
            said update shall become effective, which is a minimum of two weeks
            after it has been announced.
          </p>
        </div>

        <div className="footer-section d-none">
          <div className="footer-right-part">
            <div className="providers_img">
              <a href="javascript:void(0)">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="javascript:void(0)">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="javascript:void(0)">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="javascript:void(0)">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>
          </div>
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <div>
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/disputeresolution">Dispute Resolution</a>
                  </li>
                  <li>
                    <a href="/payout">Payout</a>
                  </li>
                  <li>
                    <a href="/registerafterlogin">Registration</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/responsiblegame">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/self">Self Exclusion</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu-web">
              <div>
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/contactus">Contact US</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/privacypolicy">Privacy</a>
                  </li>
                  <li>
                    <a href="/terms">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right d-none">
          <p>
            Copyright © 2024 <span>Sonaexch </span> | All rights reserved.
          </p>
        </div>
      </Container>

      <div className="foot-menu">
        <ul>
          <li>
            <a href="/registerafterlogin">Help</a>
          </li>
          <li>
            <a href="/privacypolicy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms">Terms & Conditions</a>
          </li>
        </ul>
      </div>

      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex m-0 p-0 list-unstyled">
          {/* <li>
              <a className="active" href="/home">
                <img src={HomeIcon} />
                <span>Home</span>
              </a>
            </li> */}
          <li>
            <a
              // className="homeactive"
              href="javascript:void(0)"
              // onClick={() => {
              //   dispatch(handleRightSideBar(!showRightSideBar));
              // }}
              onClick={() => setShowToggle((s) => !s)}
            >
              <img src={MenuIcon} />
              <span>Menu</span>
            </a>
          </li>
          <li>
            <a href="/home" className={activePage == "home" ? "active" : ""}>
              <img src={LiveCasinoIcon} />
              <span>Live Casino</span>
            </a>
          </li>
          <li>
            <a
              href="/casino"
              className={activePage == "casino" ? "active" : ""}
            >
              <img src={CasinoIcon} />
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href="/crash" className={activePage == "crash" ? "active" : ""}>
              <img src={AviatorIcon} />
              <span>Crash</span>
            </a>
          </li>
          <li>
            <a
              href="/sports"
              className={activePage == "sports" ? "active" : ""}
            >
              <img src={ExchangeIcon} />
              <span>Exchange</span>
            </a>
          </li>
          {/* <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={SupportIcon} />
              <span>Support</span>
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
