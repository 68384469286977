import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import QRCodeCard from "../../../../utils/QrCodeCard";
import {
  updateDepositAmount,
  createDeposit,
} from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import { AiFillCopy } from "react-icons/ai";

// import CopyToClipboard from "../../../components/copyToClipBoard";
import CopyToClipboard from "react-copy-to-clipboard";

const DepositGateway = () => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { gateway } = useParams();
  const isBank = gateway === "bank_transfer";
  const showQrImg = selectedGateway?.qrCode !== null;

  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);
  // console.log("selectedGateway", selectedGateway);
  const validateData = (dataToValidate) => {
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate);
    setErrors(validations);
    return validations;
  };
  const validateBankFormData = (data) => {
    const { amount, utrNo, txnImage } = data;
    let errorVar = {};
    if (!amount) {
      errorVar.amount = "Amount is required";
    }
    if (!utrNo) {
      errorVar.utrNo = "UTR is required";
    }
    if (!txnImage) {
      errorVar.txnImage = "Txn image is required";
    } else if (txnImage && txnImage?.size > 2000000) {
      errorVar.txnImage = "Image size must be less than 2MB";
    }
    setErrors(errorVar);
    return errorVar;
  };
  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    if (isBank) {
      validateBankFormData(data);
    } else {
      validateData(data);
    }

    setFormData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isBank) {
      if (Object.values(validateData({ ...formData })).length == 0) {
        // console.log("formData", formData);
        if (
          selectedGateway.fields.some(
            (f) => f?.accessor === "gateway" && f?.required === true
          )
        ) {
          formData["gateway"] = selectedGateway?.code;
        }
        const response = await updateDepositAmount({
          data: formData,
          url: selectedGateway?.url,
          isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file"),
        });
        handleSubmitResponse(response);
        // console.log("response", response);
      }
    } else {
      if (Object.values(validateBankFormData(formData)).length === 0) {
        createDeposit({ ...formData, utrNo: null })
          .then((res) => {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res.data.data.message);
          })
          .catch((err) => {
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
      }
    }
  };

  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
      } else if (value?.data?.data?.data && "payUrl" in value?.data.data.data) {
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      }
    }
  };

  const renderFields = (field) => {
    switch (field.type) {
      case "file":
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName"
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={(e) => handleChange(e, "file")}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span class="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      default:
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName "
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={handleChange}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span class="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
    }
  };

  const renderBankTransferForm = () => {
    return (
      <Form className="gatewayForm">
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"number"}
            placeholder={"Enter amount"}
            name={"amount"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["amount"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["amount"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"text"}
            placeholder={"Enter UTR no"}
            name={"utrNo"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["utrNo"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["utrNo"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"file"}
            placeholder={"Select file"}
            name={"txnImage"}
            onChange={(e) => handleChange(e, "file")}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["txnImage"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["txnImage"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Button onClick={handleSubmit}>Submit</Button>
      </Form>
    );
  };
  return (
    <>
      <HeaderAfterLogin />
      <main className="main mt-5">
        {!isBank ? (
          <Container>
            <div className="d-flex justify-content-between align-items-center PageHeading">
              <h2>{selectedGateway?.name}</h2>
            </div>
            {selectedGateway?.type === "manual" && (
              <>
                <div className="valuesDeposit">
                  {/* <h3>Wallet : {selectedGateway?.value}</h3> */}
                  <h3>UPI : {selectedGateway?.displayValue}</h3>

                  <div className="copyValue">
                    <CopyToClipboard
                      text={selectedGateway?.displayValue}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                      text={selectedGateway?.value?.slice(index1, index2)}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    /> */}
                    <div>Click to copy code</div>
                  </div>
                </div>
                {(selectedGateway?.showQr === true && (
                  <div className="d-flex">
                    {showQrImg ? (
                      <div className=" mx-auto  w-full max-w-[12rem]  relative">
                        <div className="ml-4 ">
                          <div className="bg-zinc-50 rounded-lg p-3 qrSize">
                            <img src={selectedGateway?.qrCode} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <QRCodeCard text={selectedGateway?.value} />
                    )}
                  </div>
                )) ||
                  null}
              </>
            )}
            <Form className="gatewayForm">
              {selectedGateway?.fields?.map((f, i) => {
                return renderFields(f);
              })}
              <span class="focus-border">
                <i></i>
              </span>
              <Button onClick={handleSubmit}>Submit</Button>
            </Form>
          </Container>
        ) : (
          <Container>
            <div className="d-flex justify-content-between align-items-center PageHeading">
              <h2>{"Bank Transfer"}</h2>
            </div>
            {bankDetails && (
             <div style={{ padding: "15px 0 0 5px" }}>
             <p>
               Account name : {bankDetails?.acc_name}{" "}
               <CopyToClipboard
                 text={bankDetails?.acc_name}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               >
                 <AiFillCopy />
               </CopyToClipboard>
               {/* <CopyToClipboard
                 text={bankDetails?.acc_name}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                   console.log("Abc");
                 }}
               /> */}
             </p>
             <p>
               Account number : {bankDetails?.acc_no}{" "}
               <CopyToClipboard
                 text={bankDetails?.acc_no}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               >
                 <AiFillCopy />
               </CopyToClipboard>
               {/* <CopyToClipboard
                 text={bankDetails?.acc_no}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               /> */}
             </p>
             <p>
               IFSC code : {bankDetails?.ifsc_code}{" "}
               <CopyToClipboard
                 text={bankDetails?.ifsc_code}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               >
                 <AiFillCopy />
               </CopyToClipboard>
               {/* <CopyToClipboard
                 text={bankDetails?.ifsc_code}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               /> */}
             </p>
             <p>
               Bank name : {bankDetails?.bank_name}{" "}
               <CopyToClipboard
                 text={bankDetails?.bank_name}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               >
                 <AiFillCopy />
               </CopyToClipboard>
               {/* <CopyToClipboard
                 text={bankDetails?.bank_name}
                 onCopy={() => {
                   Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                 }}
               /> */}
             </p>
           </div>
            )}
            {bankDetails && renderBankTransferForm()}
          </Container>
        )}
      </main>
      <Footer />
    </>
  );
};
export default DepositGateway;
