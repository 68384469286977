import React, { useState, useEffect } from "react";
import { Offcanvas, Modal, Form, Button } from "react-bootstrap";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { APP_CONST } from "../../../config/const";
import Whatsapp from "../../../assets/images/Whatsapp.svg";
// import LoginLeftPart from "../../../assets/images/modal.gif";

const RegisterModal = ({ show, handleClose, handleModalShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileDetails, setMobileDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [agentCode, setAgentCode] = useState(location?.state?.agentCode);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);
  const { appDetails } = useSelector((state) => state.app);
  useEffect(() => {
    getMobileDetails();
  }, []);
  //   useEffect(() => {
  //     if (referalCode) {
  //       setCookie("code", referalCode);
  //       setReferalCode(referalCode);
  //       setIsReffered(true);
  //     } else if (cookies?.code) {
  //       setReferalCode(cookies.code);
  //       setIsReffered(true);
  //     }
  //   }, [cookies, referalCode]);

  //   useEffect(() => {
  //     if (agentCode) {
  //       setAgentCookie("agent", agentCode);
  //     } else if (agentCookies?.agent) {
  //       setAgentCode(agentCookies?.agent);
  //     }
  //   }, [agentCookies, agentCode]);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword, email } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (appDetails?.IS_EMAIL) {
      if (!email || email === "") {
        newErrors.email = "Email cannot be empty";
      }
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password != confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async () => {
    let errorsData = validateData();
    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: mobileDetails?.mobile,
            country_code: mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className={"steps-canvas signup-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <div className="leftPart d-none d-xl-block"></div>
      <div className="rightPart">
        <Modal.Header
          closeButton
          onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
          closeVariant="white"
        >
          <Modal.Title>Sign up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-section">
            <div className="Login-rightPart">
              <div className="getWtspId">
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <span>
                    <span>Get an ID Instantly on Whatsapp</span>
                    <br />
                    <span>Click Here Now</span>
                  </span>
                  <img src={Whatsapp} alt="whatsapp" />
                </a>
              </div>
              <div className="form-steps">
                <Form>
                  <h6>Personal Info</h6>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Full Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {(appDetails?.IS_EMAIL && (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )) ||
                    null}
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Username*</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Username"
                      onChange={handleChange}
                      isInvalid={!!errors.userName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.userName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <h6>Password</h6>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password*</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <span
                      className="icon"
                      onClick={() => setShowPassword((p) => !p)}
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Confirm Password*</Form.Label>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <span
                      className="icon"
                      onClick={() => setShowConfirmPassword((p) => !p)}
                    >
                      {showConfirmPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Agent Code</Form.Label>
                      <Form.Control
                        type={"text"}
                        name="agent_code"
                        placeholder="Agent Code"
                        value={agentCode}
                        onChange={(e) => setAgentCode(e.target.value)}
                      />
                    </Form.Group>
                  }
                </Form>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Register
                </Button>

                <div className="sign-up">
                  Already have an account?
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      handleClose();
                      handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                    }}
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default RegisterModal;
